<template>
    <b-container>
        <b-row>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>FontAwesome</h2>
                <p>Вставка иконок FontAwesome: сначала пропихиваем их в <b>src/icons.js</b> затем используем:</p>
                <pre style="text-align: left">
                    <code>
                        {{ iconCode1 }} <font-awesome-icon icon="chevron-down" size="2xs" />
                        {{ iconCode2 }} <font-awesome-icon icon="circle" />
                        {{ iconCode3 }} <font-awesome-icon icon="fa-solid fa-circle" size="sm" />
                        {{ iconCode4 }} <font-awesome-icon icon="fa-regular fa-circle" />
                        <br />
                        {{ iconCode5 }} <font-awesome-icon :icon="['fas', 'circle']" size="lg" />
                        {{ iconCode6 }} <font-awesome-icon :icon="['far', 'circle']" />
                    </code>
                </pre>
                <p>Иконки искать тут - <b-link href="https://fontawesome.com/v6/search" target="_blank">FontAwesome</b-link></p>
            </b-col>
            <hr>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>Дата</h2>
                <p>Если втыкаем сразу в шаблон - то это используется <b><b-link href="https://www.npmjs.com/package/vue-moment" target="_blank">vue-moment</b-link></b> импортировать ни чего не нужно - все уже есть в <b>src/main.js</b></p>
                <pre style="text-align: left">
                    <code>
                        {{ dateCode1 }} - 2022
                        {{ dateCode2 }} - Thursday, June 11th 2020
                    </code>
                </pre>
                <p>Если пишем в коде - то ОБЯЗАТЕЛЬНО <b>import moment from 'moment';</b> и это будет уже <b-link href="https://momentjs.com/" target="_blank"><b>Moment.js</b></b-link> - у него совсем другая дока</p>
                <pre style="text-align: left">
                    <code>
                        {{ dateCode3 }}
                    </code>
                </pre>
            </b-col>
            <hr>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>SvgIcon</h2>
                <p style="text-align: left;">С этим модулем все плохо - сбока не работает с <b>html-loader</b>. Поэтому под каждый <b>svg</b> нужно делать свой компонент из исходика -<b>src/components/icons/ChevronLeft.vue</b> скопировать, переименовать, вставить код svg, поставить <b>fill="currentColor"</b>  и ис кода svg вытащить <b>width</b> и <b>height</b>, перенести из в <b>data()</b>, а на их место переменные как в образце.<br>Потом - <b>import ChevronLeft from '@/components/icons/ChevronLeft';</b><br>А размер можно задавать практически как угодно, если его вообще не указать - будет <b>1em</b></p>
                <svg-icon icon="eye" size="xl"/>
            </b-col>
            <hr>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>I18n</h2>
                <p style="text-align: left;">
                    Любой текст заменяется на: <b>$t('message.yes')</b> - {{ $t('message.yes') }}<br>
                    Все переводы хранятся в <b>src/locale/ru.js</b>...
                </p>
            </b-col>
            <hr>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'TestPage',
    components: {
    },
    data() {
        return {
            iconCode1: '<font-awesome-icon icon="chevron-down"  size="2xs" />',
            iconCode2: '<font-awesome-icon icon="circle" />',
            iconCode3: '<font-awesome-icon icon="fa-solid fa-circle" size="sm" />',
            iconCode4: '<font-awesome-icon icon="fa-regular fa-circle" />',
            iconCode5: '<font-awesome-icon :icon="[\'fas\', \'circle\']" size="lg" />',
            iconCode6: '<font-awesome-icon :icon="[\'far\', \'circle\']" />',
            code: '<script>',
            dateCode1: '<div>{{ new Date() | moment("YYYY") }}</div>',
            dateCode2: '<span>{{ [\'11.06.2020\', \'DD.MM.YYYY\'] | moment("dddd, MMMM Do YYYY") }}</span>',
            dateCode3: 'showMeDate () {\n' +
                '                        const date = \'2.2.20\';\n' +
                '                        return moment(date, \'D.MM.YY\').locale(\'ru\').format(\'dddd, MMMM Do YYYY\'); // воскресенье, февраль 2-го 2020\n' +
                '                    },',
            count: 20,
            spinButtonCode: '<spin-button\n' +
                '                        v-model="count"\n' +
                '                        :min="1"\n' +
                '                        :max="100"\n' +
                '                        :step="5"\n' +
                '                        pixelWidth="200"\n' +
                '                        size="lg"\n' +
                '                    />',
            chevronCode1: '<chevron-left size="sm" />     ',
            chevronCode2: '<chevron-left size="30" />     ',
            chevronCode3: '<chevron-left size="40px" />    ',
            chevronCode4: '<chevron-left />    '
        };
    }
};
</script>

<style scoped>

</style>
