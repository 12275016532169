export default {
    message: {
        back: 'Назад',
        error404: 'Такой страницы не существует!',
        loginLabel: 'Логин',
        loginModalTitle: 'Вход',
        menuAboutCompany: 'О Компании',
        menuContacts: 'Контакты',
        menuServices: 'Услуги и Товары',
        passwordLabel: 'Пароль',
        returnToMain: 'Вернуться на главную',
        yes: 'Да'
    }
};
