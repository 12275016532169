<template>
    <b-container class="partnersLogo py-5"  fluid >
        <b-row>
            <b-col  cols="12">
                <h2>Наши партнеры</h2>
            </b-col>
        </b-row>

        <b-row class="text-center pt-3">
            <b-col><img src="../assets/partners/belka.jpg"></b-col>
            <b-col><img src="../assets/partners/refuel.jpg"></b-col>
            <b-col><img src="../assets/partners/mosgortrans.jpg"></b-col>
            <b-col><img src="../assets/partners/ChistayaLiniya.jpg"></b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "PartnersBlock"
};
</script>



<style lang="less" scoped>
.partnersLogo {
    img {width: 80%;}
}
</style>
