<template>
    <div class="about">
        <b-container>
            <b-row>
                <b-col
                    class="text-start d-flex"
                    cols="6"
                >
                    <div class="text_container">
                        <p class="about_header">О компании</p>
                        <p class="about_text">Наша компания на рынке более 10 лет, десятки реализованных объектов и
                            действующих контрактов. Сотрудничаем с такими гигантами как Роснефть, Газпром и Лукойл </p>
                    </div>
                </b-col>
                <b-col
                    class="about_picture"
                    cols="6"
                />
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "AboutBlock"
};
</script>

<style lang="less" scoped>
.about {
    .text_container {
        align-self: center;
        .about_header {
            font-size: 40px;
        }

        .about_text {
        }
    }

    .about_picture {
        height: 500px;
        background-image: url("@/assets/npz2.jpg");
        background-size: cover;
        background-position: center center;
    }
}

</style>
