<template>
    <b-container class="bg-secondary-subtle text-start textBlock px-5 py-3" fluid>
        <h3>Заголовок</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur dolore eos labore neque saepe
            temporibus vero. Beatae deserunt eveniet explicabo illo perferendis? Beatae dignissimos et pariatur
            praesentium, quaerat repudiandae.
        </p>
        <p>Accusantium dolore harum id ipsam iure libero porro, quia veniam? Ab animi autem consequuntur cumque
            delectus eum excepturi, impedit ipsum iste, maiores nam possimus praesentium quae quasi qui repellat
            suscipit.
        </p>
        <p>Accusantium aliquam aperiam commodi cum dolorem doloremque dolores, facilis illum incidunt laboriosam
            laudantium, magnam nesciunt numquam optio placeat porro quod repudiandae sequi soluta suscipit, vel vero
            voluptas? Dolorem libero, repudiandae.
        </p>
        <p>Autem enim et expedita laboriosam vitae. Adipisci amet at aut doloremque dolorum eaque hic inventore iste
            minus molestiae possimus quae quibusdam quo, reprehenderit repudiandae saepe tempora, velit? Consequatur,
            dolore officiis.
        </p>


    </b-container>
</template>

<script>
export default {
    name: "TextBlock"
}
</script>

<style lang="less" scoped>
.textBlock {
    h3 {
        text-align: center;
        padding: 10px;
    }
}
</style>
