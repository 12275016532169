<template>
    <div class="test2">
        <test3
            @test="myFunction"
        />

        <p @dblclick="hi">НАЖМИ 2р</p>
    </div>
</template>

<script>
import test3 from "@/components/test/test3";
export default {
    name: "test2",
    components: {
        test3
    },
    methods: {
        myFunction() {
            console.log('Emit from test3 cached!')
        },

        hi () {
            console.log ('test222')
            this.$emit('hi');
        }
    }
}
</script>

<style scoped>
.test2 {
    width: 600px;
    height: 400px;
    background-color: #000000;
}
</style>
