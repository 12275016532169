<template>
    <site-header />
    <router-view />
    <site-footer />

</template>

<script>

import SiteHeader from '@/components/general/SiteHeader';
import SiteFooter from "@/components/general/SiteFooter";


export default {
    name: 'App',
    components: {
        SiteHeader,
        SiteFooter
    },



};
</script>

<style lang="less">
@import '../node_modules/@fontsource/roboto/index.css';
#app {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
