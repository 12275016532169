export default {
    message: {
        back: 'Back',
        error404: 'Page not found!',
        loginLabel: 'Login',
        loginModalTitle: 'Authorization',
        menuAboutCompany: 'About Company',
        menuContacts: 'Contacts',
        menuServices: 'Services',
        passwordLabel: 'Password',
        returnToMain: 'Return to main page',
        yes: 'Yes'
    }
};
