import { createApp, h } from 'vue';
import { libUtils } from "@/lib/libUtils";
import { createI18n } from 'vue-i18n';
import en from '@/locale/en';
import ru from '@/locale/ru';
import router from '@/router';
import '@/lib/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BootstrapVueNext from 'bootstrap-vue-next';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import SvgIcon from "@/components/common/SvgIcon";
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

const i18n = createI18n({
    locale: 'ru',
    messages: {
        ru,
        en
    }
});


const app  = createApp({
    render: ()=>h(App)
});

app.config.globalProperties.$declinationOfQuantity = libUtils.declinationOfQuantity;
app.config.globalProperties.$ENV = process.env.NODE_ENV;

app.use(i18n);
app.use(router);
app.use(BootstrapVueNext);
app.use(VueSvgInlinePlugin);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('svg-icon', SvgIcon);

app.mount('#app');
