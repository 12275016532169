<template>
    <div class="site-article">
        <b-container>
            <b-row>
                <b-col>
                    <h1 class="title-article">{{title}}</h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-start d-flex"
                       cols="8">
                    <div v-html="previous"></div>
                </b-col>
                <b-col cols="4">
                    <img alt="" class="img-article" :src="img" >
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-start d-flex mb-5">
                    <div v-html="text"></div>
                </b-col>
            </b-row>
        </b-container>


    </div>

</template>

<script>
export default {
    props: {
        title: {
            type:String,
            require:true
        },
        img: {
            type:String,
            require:false
        },
        previous: {
            type: String,
            require:false
        },
        text: {
            type:String,
            require:true
        },

    }

}


</script>

<style scoped>
.site-article {
    .title-article {
        color: #5b92ca;
        font-weight: bold;
        padding: 20px;
    }
}
.img-article {
    max-width: 400px;
}
</style>
