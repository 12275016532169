<template>
    <b-container class="mb-5">
        <BCardGroup
            deck
        >
            <BCard
                v-for="(card, index) in cards"
                :key="index"
                class="image-card"
                img-alt="Image"
                :img-src="card.imgCard"
                img-top
                :title="card.title"
            >
                <BCardText>{{ card.textCard }}</BCardText>
                <template #footer>
                    <BButton variant="secondary">Заказать</BButton>
                </template>
            </BCard>
        </BCardGroup>
    </b-container>


</template>

<script>
export default {
    props: {
        cards: {
            type:Array,
            require:true
        }
    }
}

</script>

<style scoped>
.image-card {
    max-width: 250px;
}
</style>
