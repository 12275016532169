<script>
export default {
    data() {
        return {
            testData: 'Q222qqqq!445676'
        };
    },
    methods: {
        testMethod(data) {
            console.log(data);
        }
    }
};
</script>
