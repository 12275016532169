<template>
    <b-container>
        <b-card no-body title="Тестовая страница">
            <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
                    <b-nav-item exact exact-active-class="active" to="/dev">Основные мелочи</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev1">Axios</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev2">.env</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev4">Телефон</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev5">Mixins</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev6">Test6</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev7">Test7</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev8">Test8</b-nav-item>
                    <b-nav-item exact exact-active-class="active" to="/dev/dev9">Test9</b-nav-item>
                </b-nav>
            </b-card-header>

            <b-card-body>
                <div>
                    <p class="text-center">Документации:
                        <b-link href="https://bootstrap-vue.org/docs/components" target="_blank"><b>Bootstrap-Vue</b></b-link> |
                        <b-link href="https://fontawesome.com/v6/search" target="_blank"><b>FontAwesome</b></b-link> |
                        <b-link href="https://www.npmjs.com/package/vue-moment" target="_blank"><b>Vue-Moment</b></b-link> |
                        <b-link href="https://momentjs.com/" target="_blank"><b>Moment.js</b></b-link> |
                        <b-link href="https://github.com/axios/axios" target="_blank"><b>Axios</b></b-link> |
                        <b-link href="https://router.vuejs.org/guide/essentials/dynamic-matching.html" target="_blank"><b>Vue-Router</b></b-link> |
                        <b-link href="https://kazupon.github.io/vue-i18n/guide/formatting.html#named-formatting" target="_blank"><b>Vue-I18n</b></b-link> |
                        <b-link href="https://vuelidate.js.org/" target="_blank"><b>Vuelidate</b></b-link>
                    </p>
                </div>
                <router-view></router-view>
            </b-card-body>
        </b-card>
    </b-container>
</template>

<script>
export default {
    name: 'TestMain'
};
</script>

<style scoped>

</style>
