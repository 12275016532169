export const libUtils = {
    formatPhone(value) {
        const val = value ? value.replace('+7', 8).replace(/\D/g, '') : null;
        const formatter = val => {
            let newVal = '+7 (';

            if (val.length === 1 && val !== '7' && val !== '8') newVal += val;
            newVal += val.slice(1, 4);
            if (val.length > 4) newVal += ') ';
            newVal += val.slice(4, 7);
            if (val.length > 7) newVal += '-';
            newVal += val.slice(7, 9);
            if (val.length >= 9) newVal += '-';
            newVal += val.slice(9, 11);
            return newVal;
        };
        return val ? formatter(val) : '';
    },

    phoneFieldFormatter(value, evt) {
        if (evt.inputType && !evt.inputType.includes('deleteContent')) {
            return this.formatPhone(value);
        } else {
            return value;
        }
    },

    onlyNumbersFormatter(value, evt) {
        if (evt.inputType && !evt.inputType.includes('deleteContent')) {
            return value.replace(/\D/g, '');
        } else {
            return value;
        }
    },

    onlyNumbers(value) {
        return !value ? value : value.replace(/\D/g, '');
    },

    /**
     * Склонение слова от количества
     * @param quantity Количество
     * @param swtOfWords скулоняемые слова ['комментарий', 'комментария', 'комментариев']
     * @returns Возвращает слово в правильном склонении
     */
    declinationOfQuantity: (quantity, swtOfWords) => {
        quantity = parseInt(quantity);
        const cases = [2, 0, 1, 1, 1, 2];

        return swtOfWords[(quantity % 100 > 4 && quantity % 100 < 20) ? 2 : cases[(quantity % 10 < 5) ? quantity % 10 : 5]];
    },

    /**
     * Хитрая проверка state для отображения корректно ли заполнено поле или нет.
     * По задумке дизайнера, когда пользователь попадает на страницу с пустыми полями он видет только серые поля без валидации.
     * А сама валидация происходит только после любых изменений в поле или валидация всех полей формы после клика по кнопке отправить.
     * :state="smartState($v.client.bik.$model, !$v.client.bik.$error)"
     */
    smartState(value, error) {
        if (!error) {
            return false;
        } else {
            if (!value) {
                return null;
            } else {
                return true;
            }
        }
    }
};
