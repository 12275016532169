<template>
    <b-container>
        <b-row>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>.env</h2>
                <p>.env функционал файлов прост: в файле <b>.env</b> - переменные будут использованы в продакшене.<br>
                    в файле <b>.env.development</b> при локальной сборке:<br>
                    VUE_APP_BASE_URL- {{ baseUrl }}<br>
                    VUE_APP_TEST - {{ test }}
                </p>
                <p>функционал в файле <b>.htaccess</b> чуть сложнее<br>
                    <b>RewriteRule .* - [E=ENVIRONMENT:production]</b> объявляем новую переменную <b>ENVIRONMENT</b> = <b>production</b><br>
                    <b>RewriteCond %{SERVER_NAME} bot.ru$</b> - если имя сервера оканчивается на bot.ru<br>
                    <b>RewriteRule .* - [E=ENVIRONMENT:development]</b> - меняем значение переменной на <b>development</b><br>
                    <b>RewriteCond %{ENV:ENVIRONMENT} production</b> - переадресация с <b>http</b> на <b>https</b> только для <b>production</b><br><br>
                    <b>RewriteCond %{SERVER_NAME} bot.ru$</b> - если имя сервера оканчивается на bot.ru<br>
                    <b>RewriteRule ^.*$ - [ENV=ADD_TEST_HEADERS:true]</b> - меняем значение переменной <b>ADD_TEST_HEADERS</b> на <b>true</b><br>
                    Далее устанавливаем 2 заголовка только для тестового сервера, что бы не получать ошибки <b>CORS</b><br>
                    <b>Header set Access-Control-Allow-Origin "http://c707.ru:8085" env=ADD_TEST_HEADERS</b><br>
                    <b>Header set Access-Control-Allow-Credentials true env=ADD_TEST_HEADERS</b><br><br>
                    Название сервера и порт задаются в <b>vue.config.js</b>
                </p>
            </b-col>
            <hr>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'TestPage2',
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL;
        },
        test() {
            return process.env.VUE_APP_TEST;
        }
    }
};
</script>

<style scoped>

</style>
