import { createWebHistory, createRouter } from 'vue-router';
import ConstructView from "@/views/ConstructView.vue";
import ContactsView from '@/views/ContactsView';
import DetailsView from "@/views/DetailsView.vue";
import ErrorPage404 from "@/views/ErrorPage404";
import HomeView from '@/views/HomeView';
import ServicesView from '@/views/ServicesView';
import SupportView from "@/views/DetailsView.vue";

import DevMain from '@/views/dev/DevMain';
import DevPage from '@/views/dev/DevPage';
import DevAxios from '@/views/dev/DevAxios';
import DevEnv from '@/views/dev/DevEnv';
import DevUtils from '@/views/dev/DevUtils';
import DevMixins from '@/views/dev/DevMixins';
import DevI18 from '@/views/dev/DevI18';
import DevPage7 from '@/views/dev/DevPage7';
import DevPage8 from '@/views/dev/DevPage8';
import DevPage9 from '@/views/dev/DevPage9';


function TestGuard(to, from, next) {
    if (process.env.NODE_ENV === 'development') {
        next();
    } else {
        next('/');
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/services',
        name: 'services',
        component: ServicesView
    },
    {
        path: '/services/construct',
        name: 'construct',
        component: ConstructView
    },
    {
        path: '/services/details',
        name: 'details',
        component: DetailsView
    },
    {
        path: '/services/support',
        name: 'support',
        component: SupportView
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsView
    },
    {
        path: '/dev',
        component: DevMain,
        beforeEnter: TestGuard,
        children: [
            {
                path: '',
                name: 'dev',
                component: DevPage
            },
            {
                path: 'dev1',
                name: 'dev1',
                component: DevAxios
            },
            {
                path: 'dev2',
                name: 'dev2',
                component: DevEnv
            },
            {
                path: 'dev4',
                name: 'dev4',
                component: DevUtils
            },
            {
                path: 'dev5',
                name: 'dev5',
                component: DevMixins
            },
            {
                path: 'dev6',
                name: 'dev6',
                component: DevI18
            },
            {
                path: 'dev7',
                name: 'dev7',
                component: DevPage7
            },
            {
                path: 'dev8',
                name: 'dev8',
                component: DevPage8
            },
            {
                path: 'dev9',
                name: 'dev9',
                component: DevPage9
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: ErrorPage404
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
