<template>
    <b-container>
        {{ $i18n.locale }}
    </b-container>
</template>

<script>
export default {
    name: 'TestPage6',
    data() {
        return {
            phone: null
        };
    }
};
</script>

<style scoped>

</style>
