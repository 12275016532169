<template>
    <b-container>
        <button>
            Применить 1
        </button>

        <button>
            Применить 2
        </button>

        <button>
            Применить 3
        </button>

        <button>
            Применить 4
        </button>

        <button>
            Применить 5
        </button>
    </b-container>
</template>

<script>
export default {
    name: 'TestPage9',
    data() {
        return {
            phone: null
        };
    }
};
</script>

<style scoped>
button {
    border-radius: 5px;
    min-height: 30px;
    min-width: 50px;
    margin: 50px;
}

button:hover {
    box-shadow: 0 0 10px 20px #cccccc;
}

button:focus {
   outline: 4px solid #42a7b9;
}

button:active {
    border: 2px solid red;
}
</style>
