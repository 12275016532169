/* Set up using Vue 3 */

import { library as iconLibrary } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle as fasCircle,
    faMinus,
    faPhone,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

import {
    faCircle,
    faClock as farClock,
    faEye,
    faEyeSlash,
    faPauseCircle,
    faTimesCircle
} from '@fortawesome/free-regular-svg-icons';

/* eslint-disable no-trailing-spaces */
iconLibrary.add(
    // solid
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faMinus,
    faPhone,
    faPlus,
    fasCircle,

    // regular
    faCircle,
    farClock,
    faEye,
    faEyeSlash,
    faPauseCircle,
    faTimesCircle
);
