<template>
    <div class="details">
        <site-article
            :img="article.img"
            :previous="article.previous"
            :text="article.text"
            :title="article.title">
        </site-article>

        <site-cards
            :cards="cards"></site-cards>

    </div>
</template>

<script>

import SiteArticle from "@/components/general/SiteArticle.vue";
import SiteCards from "@/components/general/SiteCards.vue";

export default {
    name: 'DetailsView',
    components: {
        SiteArticle,
        SiteCards
    },
    data() {
        return {
            article: {
                title:'Комплектующие для спецтехники',
                img: require('@/assets/construct/2.jpg'),
                previous:'<p>Спецтехнику используют во всех стратегически важных отраслях: строительное дело, дорожное хозяйство, грузоперевозки. Она предназначена для выполнения задач, которые не могут быть эффективно выполнены традиционными методами. Для обеспечения бесперебойной работы спецтехники важно иметь доступ к качественным запчастям и своевременно их заменять или обновлять:</p>' +
                    '<ul>\n' +
                    '\t<li>\n' +
                    '\tРемонт и замена изношенных деталей: при эксплуатации спецтехники запчасти изнашиваются и ломаются. Покупка новых или восстановленных деталей позволит восстановить функциональность и техники.\n' +
                    '\t</li>\n' +
                    '\t<li>\n' +
                    'Расширение парка спецтехники: приобретение дополнительных компонентов (ковши для экскаваторов, вилы для погрузчиков и другие аксессуары) позволит расширить бизнес и повысить конверсию.\n' +
                    '\t</li>\n' +
                    '\t<li>\n' +
                    'Модернизация и оптимизация: для повышения производительности запчасти для спецтехники нужно закупать только у лучших поставщиков, а Trade Service – один из них.\n' +
                    '\t</li>\n' +
                    '</ul>',
                text:''
            },
            cards: [
                {
                    id: 1,
                    imgCard: require('@/assets/construct/3.jpg'),
                    title:'Насадка для кнопок К-902',
                    textCard: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. '
                },
                {
                    id: 2,
                    imgCard: require('@/assets/construct/4.jpg'),
                    title:'Насадка для кнопок',
                    textCard: 'В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
                },
                {
                    id: 3,
                    imgCard: require('@/assets/construct/5.jpg'),
                    title:'Рукав подключения ГУР',
                    textCard: 'Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. '
                },
                {
                    id: 4,
                    imgCard: require('@/assets/construct/3.jpg'),
                    title:'Насадка для кнопок К-902',
                    textCard: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. '
                },
                {
                    id: 5,
                    imgCard: require('@/assets/construct/4.jpg'),
                    title:'Насадка для кнопок',
                    textCard: 'В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
                }
            ]
        }
    },
    computed: {
        env() {
            return this.$ENV;
        }
    }
};
</script>

<style lang="less" scoped>
.construct {
    h1 {
        color: #5b92ca;
        font-weight: bold;
    }
}

.img-article {
    max-width: 400px;
}
</style>
