<template>
    <b-container
        class="npz p-0"
        fluid
    >
        <div class="overlay">
            <div class="text">Наша компания на рынке более 10 лет, десятки реализованных объектов и действующих контрактов. Сотрудничаем с такими гигантами как Роснефть, Газпром и Лукойл.
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "NpzBlock"
};
</script>

<style lang="less" scoped>
.npz {
    height: 500px;
    background-image: url("@/assets/npz1.jpg");
    background-size: cover;
    background-position: center center;

    div.overlay {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);

        div.text {
            width: 700px;
            align-self: center;
            font-size: 26px;
            color: #ffffff;
            text-shadow: 1px 1px #000000;
        }
    }
}

</style>
