<template>
    <div class="contacts">
        <h1>Контакты!</h1>
    </div>
</template>

<script>
export default {
    name: 'ContactsView',
    computed: {
        env() {
            return this.$ENV;
        }
    }
};
</script>

<style lang="less" scoped>
.contacts {
    h1 {
        color: #5b92ca;
        font-weight: bold;
    }
}
</style>
