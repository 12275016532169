<template>
    <button
        @click="testFunction"
    >
        CLICK_ME
    </button>
    <p>12 {{ $declinationOfQuantity(12, ['бутылка', 'бутылки', 'бутылок']) }}</p>
    <p>12 {{ showTestDeclination() }}</p>
</template>

<script>
export default {
    name: "test3",
    methods: {
        testFunction() {
            console.log('test3!!!!!');
            this.$emit('test');
        },

        showTestDeclination() {
            return this.$declinationOfQuantity(12, ['бутылка', 'бутылки', 'бутылок']) ;
        }
    }
}
</script>

<style scoped>

</style>
