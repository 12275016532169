<template>
    <b-container>
        <input placeholder="input 1">
        <input placeholder="input 2">
        <input placeholder="input 3">
        <input placeholder="input 1">
        <input placeholder="input 1">

    </b-container>
</template>

<script>
export default {
    name: 'TestPage8',
    data() {
        return {
            phone: null
        };
    }
};
</script>

<style scoped>
input {
    border-radius: 5px;
    min-height: 30px;
    width: 100px;
    margin: 50px;
}

input:hover {
    box-shadow: 0 0 10px 20px #cccccc;
}

input:focus {
    outline: 4px solid #42a7b9;
}

input:active {
    border: 2px solid red;
}
</style>
