<template>
    <i class="svg-container">
        <img
            v-svg-inline
            alt=""
            :src="require(`@/${this.realPath}${this.realIcon}.svg`)"
        />
    </i>
</template>

<script>
/** Это мой модифицированный компонент SvgIcon он работает в этой сборке с "vue-svg-inline-plugin" **/
const sizes = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40
};

const DEFAULT_SIZE = 'md';
const SIZE_ARRAY = Object.keys(sizes);

const recursivelyRemoveFill = element => {
    if (!element) {
        return;
    }

    element.removeAttribute('fill');

    [].forEach.call(element.children, child => {
        recursivelyRemoveFill(child);
    });
};

export default {
    name: 'SvgIconTemplate',

    props: {
        // Имя файла иконки
        icon: {
            type: String,
            required: true
        },

        // папка иконок
        path: {
            type: String,
            default: 'assets/icons/'
        },

        // размер иконки (ШИРИНА!), может быть задан как 'sm', 'xl', так и '40', '25 px', так и не задан вовсе
        size: {
            type: [String, Number],
            default: null,
            validator: (value) => SIZE_ARRAY.indexOf(value) > -1 || parseInt(value.replace(['px', ' '], ''), 10) > 5
        },

        // Использовать оригинальный размер SVG
        defaultSize: {
            type: Boolean,
            default: false
        },

        // Если иконка поликолорная, то можем сохранить родные цвета
        originalFill: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            realIcon: '',
            realPath: ''
        };
    },

    methods: {
        /**
         * Функция проверки, если ли в принципе файл с такой иконкой. Пришлось выновить в функцию так как,
         * бывают случаи смены иконки без перестройко компонента
         * Если файла нету - меняем на кружек со знаком вопроса.  'ico-question'
         */
        checkRealIcon() {
            try {
                require(`@/${this.path}${this.icon}.svg`);
                this.realIcon = this.icon;
                this.realPath = this.path;
            } catch {
                this.realIcon = 'question-circle-solid';
                this.realPath = 'assets/icons/';
            }
        },

        /**
         * Разбираем и собираем заново SVG c учетом нужных нам размеров и цвета
         */
        modifySVG() {
            if (this.$el && this.$el.firstElementChild && this.$el.firstElementChild.nodeName === 'svg') {
                // пробуем получить цифру ширины из пришедших пропсов.
                let size = sizes[this.size];

                // если не пришел параметр типа sm или lg - смотрим не пришла ли цифра, если да то берем ее.
                if (!size && /^\d+$/.test(this.size)) {
                    size = this.size;
                }

                // проверяем, вдруг ввели size как '24 px'
                if (this.size && parseInt(this.size.replace('px', ''), 10) > 5) {
                    size = parseInt(this.size.replace('px', ''), 10);
                }

                if (!size && !this.defaultSize) {
                    size = sizes[DEFAULT_SIZE];
                }

                // цепляем саму svg'ху
                const svgElement = this.$el.firstElementChild;

                // вытаскиваем у нее viewBox бьем в массив
                const viewBox = svgElement.getAttribute('viewBox').split(' ').map(n => Number(n));

                // получаем пропорцию отношения ее родных ширины к высоте
                const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);

                // если параметр originalFill отсутвует то сносим все fill
                if (!this.originalFill) {
                    // из за ошибок создания svg fill может встречаться на совершенно разных уровнях вложенности <g><g><g><path.. fill=
                    recursivelyRemoveFill(svgElement);

                    // Задаем глобальный fill = currentColor
                    svgElement.setAttribute('fill', 'currentColor');
                }

                // если удалось получить size и не стоит параметр использовать оригинальный размер
                if (size && !this.defaultSize) {
                    svgElement.setAttribute('height', size + 'px');
                    svgElement.setAttribute('width', size * widthToHeight + 'px');
                }
            }
        }
    },

    watch: {
        /**
         * смотрим за пропсом, если изменился входящий параметр - проверяем есть ли новая иконка
         */
        icon() {
            this.checkRealIcon();
        },

        originalFill() {
            this.modifySVG();
        }
    },

    beforeMount() {
        this.checkRealIcon();
    },

    mounted() {
        setTimeout(() => {
            this.modifySVG();
        }, 0);
    },

    updated() {
        /**
         * опять же,ed если поменялась в компоненте входная иконка - нужно пересобирать svg
         */
        this.modifySVG();
    }
};
</script>
