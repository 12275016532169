<template>
    <b-container>
        <b-row>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>Телефон</h2>
                <b-form-input
                    v-model="phone"
                    autofocus
                    class="w-25"
                    :formatter="getFormattedPhone"
                />
                {{ phone }}
                <br>
                {{ getOnlyNumbers }}
            </b-col>
            <hr>
        </b-row>
    </b-container>
</template>

<script>
import { libUtils } from '@/lib/libUtils';

export default {
    name: 'TestPage4',
    data() {
        return {
            phone: null
        };
    },
    computed: {
        getOnlyNumbers() {
            return libUtils.onlyNumbers(this.phone);
        }
    },
    methods: {
        getFormattedPhone(value, evt) {
            return libUtils.phoneFieldFormatter(value, evt);
        }
    }
};
</script>

<style scoped>

</style>
