<template>
    <b-container>
        <b-row>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>Axios</h2>
                <p><b-link href="https://github.com/axios/axios" target="_blank"><b>Axios</b></b-link> уже подключен в <b>src/main.js</b> как <b>Vue.prototype.HTTP</b></p>
                <b-form
                    class="d-flex"
                    inline
                    @submit="sendRequest"
                >
                    <b-form-input
                        id="inline-form-input-name"
                        v-model="answer"
                        class="mx-2 w-25"
                        placeholder="Нажми на кнопку"
                    />

                    <b-button type="submit" variant="primary">Отправить запрос</b-button>
                </b-form>
                <pre style="text-align: left">
                    <code>
                        {{ code }}
                    </code>
                </pre>
            </b-col>
            <hr>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'TestPage1',
    data() {
        return {
            answer: null,
            code: '' +
                '   async sendRequest () {\n' +
                '                           event.preventDefault(); // только если ловим с кнопки submit\n' +
                '                           try {\n' +
                '                               const response = await this.HTTP.get(\'/scripts/test2.php\');\n' +
                '                               this.answer = response.data;\n' +
                '                           } catch (e) {\n' +
                '                               console.log(e);\n' +
                '                           }\n' +
                '                       }'
        };
    },
    methods: {
        async sendRequest(event) {
            event.preventDefault();
            try {
                const response = await this.HTTP.get('/scripts/test3.php');
                this.answer = response.data;
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>
