<template>
    <main-carousel />
    <about-block />
    <n-p-z />
    <ClientsBlock/>
    <TextBlock/>
    <PartnersBlock/>
</template>

<script>
import NpzBlock from "@/components/NpzBlock";
import AboutBlock from "@/components/AboutBlock";
import PartnersBlock from "@/components/PartnersBlock";
import TextBlock from "@/components/TextBlock";
import ClientsBlock from "@/components/ClientsBlock";
import MainCarousel from "@/components/MainCarousel";

export default {
    name: 'HomeView',
    components: {
        MainCarousel,
        NPZ: NpzBlock,
        AboutBlock,
        PartnersBlock,
        TextBlock,
        ClientsBlock
    },
    props: {
        msg: String
    },
    computed: {
        env() {
            return this.$ENV;
        },
        showTestDeclination() {
            return this.$declinationOfQuantity(12, ['бутылка', 'бутылки', 'бутылок']);
        }
    }
};
</script>

<style>

</style>
