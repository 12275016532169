<template>
    <footer>
        <div>
            © B-Engineering 2023
        </div>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter',
    computed: {
        env() {
            return this.$ENV;
        },
        userIsLoginIn() {
            return false;
        }
    }
};
</script>

<style lang="less" scoped>
footer {
    width: 100%;
    background-color: #000000;

    div {
        color: #ffffff;
        padding: 10px 0;
    }
}
</style>
