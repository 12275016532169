<template>
    <h1>{{ $t('message.error404') }}</h1>
    <b-link to="/">{{ $t('message.returnToMain') }}</b-link>
</template>

<script>
export default {
    name: "ErrorPage404"
};
</script>

<style scoped>

</style>
