<template>
    <b-container>
        <b-row>
            <b-col
                class="mb-5"
                cols="12"
            >
                <h2>Mixins</h2>
                <p>Есть 2 варианта выноса кода в доп файлы:<br>
                    <b>import { libUtils } from '@/lib/libUtils';</b> =====>   <b>libUtils.onlyNumbers(....);</b><br>
                    <b>import MyMixins from '@/lib/mixins';</b>  =====> <b>mixins: [MyMixins]</b>  =====>  this.testMethod(....)
                </p>
                {{ testData }}
                <br>
                {{ getOnlyNumbers }}
            </b-col>
            <hr>
        </b-row>
    </b-container>
</template>

<script>
import { libUtils } from '@/lib/libUtils';
import mixins from '@/lib/globalMixins';

export default {
    name: 'TestPage5',
    mixins: [
        mixins
    ],
    computed: {
        getOnlyNumbers() {
            return libUtils.onlyNumbers(this.testData);
        }
    },
    mounted() {
        this.testMethod(this.testData);
    }
};
</script>

<style scoped>

</style>
