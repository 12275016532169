<template>
    <b-container>
        <b-row>
            <b-col cols="12">
                <test1 />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import test1 from "@/components/test/test1";
export default {
    name: 'TestPage7',
    components: {
        test1
    },
    data() {
        return {
            phone: null,
            imgPath: require('@/assets/slider/1.jpg')
        };
    },
    mounted() {
        console.log(this.imgPath);
    }
};
</script>

<style scoped>

</style>
