<template>
    <b-container class="firms-logo py-5" fluid>
        <b-row>
            <b-col cols="12">
                <h2>Наши клиенты</h2>
            </b-col>
        </b-row>

        <b-row class="text-center">
            <b-col cols="3"><img src="../assets/clients/gazprom.jpg"></b-col>
            <b-col cols="3"><img src="../assets/clients/lukoil.jpg"></b-col>
            <b-col cols="3"><img src="../assets/clients/trassa.jpg"></b-col>
            <b-col cols="3"><img src="../assets/clients/rosneft.jpg"></b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "ClientsBlock"
};
</script>



<style lang="less" scoped>
.firms-logo {
    img {width: 75%}
}
</style>
