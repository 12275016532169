<template>
    <div class="test1">
        <test2
            @hi="hello"/>
    </div>
</template>

<script>
import test2 from "@/components/test/test2";
export default {
    name: "test1",
    components: {
        test2
    },
    methods: {
        hello () {
            console.log ('Привет привет')
        }
    }
}
</script>

<style scoped>
.test1 {
    width: 800px;
    height: 500px;
    background-color: #42a7b9;
}
</style>
