<template>
    <Carousel :autoplay="4000" :transition="1000" :wrapAround="true" >

        <Slide v-for="slide in Images" :key="slide.id">
            <div class="carousel-block">
                <img alt="" class="w-100"  :src="slide.src"/>
                <div :class="[slide.right ? 'carousel-block-text-right' : 'carousel-block-text-left', slide.themeBlack ? 'black-theme' : 'white-theme']">
                    <p class="h3">{{ slide.title }} </p>
                    <p>{{slide.text}} </p>
                    <a
                        v-if="slide.link.includes('http')"
                        :class="['btn', slide.themeBlack ? 'btn-light' : 'btn-dark']"
                        :href="slide.link"
                    >
                        Подробнее
                    </a>
                    <BButton
                        v-else
                        :to="slide.link"
                        type="button"
                        :variant="[slide.themeBlack ? 'light' : 'dark']"
                    >
                        Подробнее
                    </BButton>
                </div>
            </div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'Basic',
    data() {
        return {
            Images: [
                {
                    id: 1,
                    src: require('@/assets/slider/1.jpg'),
                    title: 'Строительство нефтебаз и нефтехранилищ',
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    link:'/construct',
                    themeBlack: false,
                    right: false
                },
                {
                    id: 2,
                    src: require('@/assets/slider/2.jpg'),
                    title: 'Обслуживание промышленных предприятий',
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    link:'https://ya.ru/',
                    themeBlack: true,
                    right: false
                },
                {
                    id: 3,
                    src: require('@/assets/slider/3.jpg'),
                    title: 'Поставка запчастей на спецтехнику',
                    link:'/details',
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    themeBlack: true,
                    right: false
                },
                {
                    id:4,
                    src: require('@/assets/slider/4.jpg'),
                    title: 'Поставка топлива , масел и др нефтепродуктов',
                    text:
                        '44444444444444444 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    link:'',
                    themeBlack: false,
                    right: true
                },
                {
                    id:5,
                    src: require('@/assets/slider/5.jpg'),
                    title: 'Модернизация нефтебаз и промышленных предприятий ',
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    link:'',
                    themeBlack: true,
                    right: false
                },
                {
                    id:4,
                    src: require('@/assets/slider/6.jpg'),
                    title: 'Установка мобильных мини АЗС',
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam dolorem doloribus, dolorum error eveniet exercitationem id inventore iste laboriosam natus obcaecati praesentium, quaerat quia ratione recusandae sint temporibus velit.',
                    link:'',
                    themeBlack: true,
                    right: true
                }
            ],
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },


})
</script>

<style lang="less">
.carousel {
    ol {
        padding: 0 0 0 50%;
        margin: 0;
    }

    &__item {
        min-height: 200px;
        width: 100%;
        background-color: var(--vc-clr-primary);
        color: var(--vc-clr-white);
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__slide {
    }

    &__prev,
    &__next {
        box-sizing: content-box;
        height: 100%;
        padding: 0;
        margin: 0;
        width: 9%;
    }

    &__prev {
        background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
        padding: 0;

        &:hover {
            background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 100%);
        }
    }

    &__next {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
        padding: 0;

        &:hover {
            background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.7) 100%);

        }
    }

    &__icon {
        color: white;
        font-size: 50px;
        opacity: 0.5;

        &:hover {
            opacity: 0.9;
        }
    }

    &__pagination {
        position: absolute;
        bottom: 20px;
        padding-left: 50%;
    }
}

.carousel-block {
    position: relative;

    &-text-right {
        position: absolute;
        padding: 10px;
        top: 20%;
        right: 10%;
        width: 45%;
        box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);
    }

    &-text-left {
        position: absolute;
        padding: 10px;
        bottom: 20%;
        left: 10%;
        width: 45%;
        box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);
    }
}

.black-theme {
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

.white-theme {
    background: rgba(255, 255, 255, 0.5);
    color: black;
}


</style>
