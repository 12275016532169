<template>
    <b-navbar
        class="custom_navbar"
        sticky="top"
        toggleable="md"
        type="light"
    >
        <b-navbar-brand
            class="custom_brand"
            to="/"
        >
            <img alt="B-Engineering" src="@/assets/logo.png">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav fill>
                <b-nav-item
                    class="main-menu-item"
                    exact
                    exact-active-class="active"
                    to="/"
                >
                    {{ $t('message.menuAboutCompany') }}
                </b-nav-item>
                <!--<b-nav-item-dropdown exact exact-active-class="active" right to="/services" >-->

                <b-nav-item-dropdown right to="/services">

                    <template #button-content>
                        {{$t('message.menuServices')}}
                    </template>

                    <b-dropdown-item link-class="drop-active" to="/services/construct">Строительство нефтебаз</b-dropdown-item>
                    <b-dropdown-item link-class="drop-active" to="/services/details">Комплектующие</b-dropdown-item>
                    <b-dropdown-item link-class="drop-active" to="/services/support">Сервисное обслуживание</b-dropdown-item>
                    <b-dropdown-item href="#" link-class="drop-active">мини АЗС</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item exact exact-active-class="active" to="/contacts">{{ $t('message.menuContacts') }}</b-nav-item>

                <b-nav-item
                    v-if="env === 'development'"
                    :active="this.$route.path.includes('/dev')"
                    exact
                    exact-active-class="active"
                    to="/dev"
                >
                    Dev
                </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ms-auto">
                <b-nav-item class="fw-bold" href="tel:+74959918080">
                    <font-awesome-icon icon="phone" />
                    +7(495)991-80-80
                </b-nav-item>

                <b-nav-item class="fw-bold" href="tel:+79859918080">
                    <font-awesome-icon icon="phone" />
                    +7(985)991-80-80
                </b-nav-item>

                <b-nav-item-dropdown
                    v-if="$i18n"
                    right
                >
                    <template #button-content>
                        <b>{{ $i18n.locale.toLocaleUpperCase() }}</b>
                    </template>
                    <b-dropdown-item
                        :link-class="$i18n.locale === 'ru' ? 'aaa' : 'bbb'"
                        @click="setLocale('ru')"
                    >
                        RU
                    </b-dropdown-item>
                    <b-dropdown-item
                        :link-class="$i18n.locale === 'en' ? 'aaa' : 'bbb'"
                        @click="setLocale('en')"
                    >
                        EN
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <div
                    v-if="!userIsLoginIn"
                    class="header_button_container"
                >
                    <b-button
                        class="header_login_button"
                        size="sm"
                        variant="secondary"
                        @click="openLoginModal"
                    >
                        Вход
                    </b-button>
                </div>

                <b-nav-item-dropdown
                    v-else
                    right
                >
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <BAvatar
                            size="1.5rem"
                            text="MJ"
                            variant="secondary"
                        />
                        @MegaJohn
                    </template>
                    <b-dropdown-item href="#">Profile</b-dropdown-item>
                    <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: 'SiteHeader',
    computed: {
        env() {
            return this.$ENV;
        },
        userIsLoginIn() {
            return false;
        }
    },
    methods: {
        setLocale(lang) {
            if ((lang === 'ru' || lang === 'en') && this.$i18n.locale !== lang) {
                this.$i18n.locale = lang;
            }
        },
        openLoginModal() {
            this.$store.commit('setShowLoginModal', true);
        }
    }
};
</script>

<style lang="less" scoped>
.custom_navbar {
    padding: 0 15px;
    background-color: #cccccc;
}

@media (min-width: 576px) {
    .custom_navbar {
        padding: 0 calc((100% - 510px)/2);
    }
}
@media (min-width: 768px) {
    .custom_navbar {
        padding: 0 calc((100% - 690px)/2);
    }
}
@media (min-width: 992px) {
    .custom_navbar {
        padding: 0 calc((100% - 930px)/2);
    }
}
@media (min-width: 1200px) {
    .custom_navbar {
        padding: 0 calc((100% - 1110px)/2);
    }
}

@media (min-width: 1400px) {
    .custom_navbar {
        padding: 0 calc((100% - 1320px)/2);
    }
}

.custom_navbar .custom_brand {
    margin-right: 3rem;
    padding: 0;

    img {
        height: 40px;
        width: auto;
    }
}

.header_button_container {
    display: flex;
}

.header_button_container .header_login_button {
    display: flex;
    height: fit-content;
    align-self: center;
    /*padding-left: 15px;
    padding-right: 15px;*/
}
</style>

<style>

a.drop-active:active {
    background-color: #5b92ca !important;
}

.aaa {
    background-color: #5b92ca !important;}
</style>
